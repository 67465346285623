<template>
    <div style="background-color:#F2F2F2 ;">
         <a-card style="margin-bottom:20px">
            <a-form layout="inline" :form="form" >
                <a-form-item label="IP:">
                    <a-input v-model="form.ip" placeholder="搜索IP"/>
                </a-form-item>
                
                
                <a-form-item>
                    <a-button type="primary" icon="search" @click="handleSearch">
                        搜索
                    </a-button>    
                </a-form-item>
            </a-form>
            </a-card>
        <a-card>
            <a-table
            :columns="columns"
            :data-source="tableData"
            :row-key="(record) => record._id"
            :pagination="false"
            :loading="loading"
            >
                <template slot="action" slot-scope="record">
                    <!-- <a href="javascript:;">下线</a> -->
                    <a-button type="link" :disabled="record.users.length==0"  @click="handleShow(record)">查看</a-button>
                </template>
                <span slot="ratio" slot-scope="text">{{text||'-'}}</span>
                <span slot="status" slot-scope="text">
                    <a-tag :color="text===0?'red':'green'">{{text===0?'停用':'启用'}}</a-tag>
                </span>
                <a-button slot="onlineNum" slot-scope="text,record" type="link" :disabled="record.users.length==0" @click="handleShow(record)">{{record.users.length}}</a-button>
            </a-table>
        </a-card>
        <a-modal v-model="showOnline" title="查看在线小助手" width="60%"  :bodyStyle="{height:'500px',overflow:'scroll'}">
            <a-list
                class="demo-loadmore-list"
                item-layout="horizontal"
                :data-source="onlineUsers"
            >
                <a-list-item slot="renderItem" slot-scope="item">
                    <a-list-item-meta >
                    <div slot="title">{{ item.username }}</div>
                    <div slot="description">{{item.acctid}}</div>
                    <a-avatar
                    slot="avatar"
                    :src="item.avatar"
                    />
                </a-list-item-meta>
                <!-- <div>content</div> -->
                </a-list-item>
            </a-list>
            <div slot="footer" class="modal-footer">
                <!-- <a-button type="primary">确定</a-button> -->
                <a-button @click="showOnline = false">关闭</a-button>
            </div>
        </a-modal>
        <a-modal v-model="showError" title="处理异常" width="300px">
            <p>将"心跳异常"状态更新为"<span style="color:green">正常</span>"状态</p>
            <div slot="footer" class="modal-foot">
                <a-button type="primary">确定</a-button>
                <a-button @click="showError = false">取消</a-button>
            </div>
        </a-modal>
    </div>
</template>
<script>
const columns = [
   {
      title: "IP",
      dataIndex: "ip",
      align: "center",
    },
    {
      title: "节点名称",
      dataIndex: "clientId",
      align: "center",
      ellipsis: true,
    },
    {
      title: "在线小助手",
      dataIndex: "onlineNum",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'onlineNum' },
    },
    {
      title: "设备",
      dataIndex: "computer_name",
      align: "center",
      ellipsis: true,
    },
    {
      title: "内存使用率",
      dataIndex: "ratio",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'ratio' },
    },
    {
      title: "节点状态",
      dataIndex: "status",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'status' },
    },
    {
      title: "操作",
      key: "action",
      scopedSlots: { customRender: 'action' },
    }
]

import {
    mapState
  } from 'vuex'
export default {
    name:'heartbeat',
    data(){
        return{
            form:{
                ip:''
            },
            handleInterval:null,
            columns,
            tableData:[],
            onlineUsers:[],
            loading:false,
            showOnline:false,
            showError:false,
        }
    },
    computed: {
        ...mapState(['mqttInit','maskStatus'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.getDataList()
                    this.handleInterval = setInterval(()=>{
                        this.handleGetRatio()
                    },60000)
                }
            },
            immediate:true
        },
        maskStatus(){
            this.loading = false
        }
    },
    destroyed(){
        clearInterval(this.handleInterval)
    },
    methods:{
        handleGetRatio(){
            let empty = []
            this.tableData.forEach((item,index)=>{
                if(empty.length===0 && index===0){
                    empty.push(item)
                }else{
                    let flag = false
                    empty.forEach(obj=>{
                        if(obj.ip===item.ip){
                            flag = true
                        }
                    })
                    if(!flag){
                        empty.push(item)
                    }
                }

            })
            empty.forEach(item=>{
                if(item.status!==0){
                    this.getCPUUseRatio(item)
                }
            })
            
        },
        getCPUUseRatio(item){
           let payload = {
                msgId:'memory',
                code: "memory",
            };
            // console.log(11111)
             this.$mqtt.doPublish(
                {
                    pubTopic: `wx_robot/${item._id}`,
                    payload,
                },
                (topic, data) => {
                   let ip = this.tableData.filter(item=>{
                       return item._id === data.clientId
                   })[0].ip
                  this.tableData.forEach((item,index)=>{
                      if(item.ip===ip){
                          this.$set(this.tableData[index],'ratio',Number(data.msg).toFixed(2)+'%')
                        //   item.ratio = data.msg+'%'
                      }
                  })
                },
                this
            );
        },
        handleSearch(){
            this.getDataList()
        },
        getDataList(){
            if(!this.mqttInit){
                this.$message.info('连接中...')
                return
            }
            let clientId = lockr.get('clientId')
            this.loading = true
            let payload = {
                msgId:'monitor',
                data: {
                    code: "monitor",
                    ...this.form
                },
            };

            this.$mqtt.doPublish(
                {
                    pubTopic: `scrm_sys/${clientId}/app`,
                    payload,
                },
                (topic, data) => {
                    // console.log(data)
                    this.loading = false
                    let {clients,users} = data.data
                    clients.forEach(client=>{
                        client.users = []
                    })
                    
                    for(let i =0,len=users.length;i<len;i++){
                        let user = users[i]
                        for(let k = 0,l=clients.length;k<l;k++){
                            let client = clients[k]
                            if(user.clientId === client._id){
                                client.users.push(user)
                                break;
                            }
                        }
                    }
                    this.tableData = clients
                    this.handleGetRatio()
                },
                this
            );
        },
        handleShow(val){
            // console.log(val)
            // if(val.users.length<=0){
            //     return
            // }
            this.onlineUsers = val.users
            this.showOnline = true
        },
        handleError(){
            this.showError = true
        },
    }
}
</script>
<style lang="scss" scoped>
.title{
    font-size: 20px;
    font-weight: bold;
    color: #333;
}
.modal-foot{
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>